
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class extends Vue {
  public created(): void {
    const { params, query } = this.$route;
    const { path } = params;
    this.$router.replace({ path: '/' + path, query }).catch(err => {
      console.warn(err);
    });
  }

  public render(): void {
    // 避免因缺少模板而发出警告
  }
}
